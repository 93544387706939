import React, { MouseEventHandler } from 'react';
import Link from 'next/link';
import { Colors } from '@/style/colors';
import { Button, ButtonAdditionalText, ButtonIcon, ButtonTitle } from './PrimaryButton.styled';

export interface IPrimaryButton {
    href?: string;
    target?: '_blank' | '_self';
    bgColor?: Colors;
    textColor?: Colors;
    type?: 'reset' | 'button' | 'submit';
    variant: 'black' | 'white' | 'white-bordered' | 'light-bg';
    title?: string;
    onClick?: MouseEventHandler<Element> | undefined;
    children?: React.ReactNode; // SvgElements['name']
    disabled?: boolean;
    collapsed?: boolean;
    additionalText?: string;
}
const PrimaryButton = ({
    href,
    target,
    bgColor,
    disabled,
    textColor,
    type,
    variant,
    title,
    onClick,
    children,
    collapsed,
    additionalText
}: IPrimaryButton) => {
    if (href) {
        return (
            <Link href={href} passHref target={target}>
                <Button
                    as="a"
                    bgColor={bgColor}
                    textColor={textColor}
                    type={type || 'button'}
                    variant={variant}
                    collapsed={collapsed}
                    onClick={onClick}
                >
                    {children && <ButtonIcon>{children}</ButtonIcon>}
                    <ButtonTitle collapsed={collapsed}>{title}</ButtonTitle>
                </Button>
            </Link>
        );
    }

    return (
        <Button
            bgColor={bgColor}
            textColor={textColor}
            disabled={disabled}
            type={type || 'button'}
            variant={variant}
            onClick={onClick}
            collapsed={collapsed}
        >
            {children && <ButtonIcon>{children}</ButtonIcon>}
            <ButtonTitle collapsed={collapsed}>{title}</ButtonTitle>
            {additionalText && <ButtonAdditionalText>{additionalText}</ButtonAdditionalText>}
        </Button>
    );
};

export default PrimaryButton;
