import { color, font, hover, mediaBreakpointUp, offset } from '@/style/mixins';
import variables from '@/style/variables';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 899;
    left: 20px;
    right: 20px;
    bottom: 12px;
    background-color: ${color('Content-03')};
    border-radius: ${variables.corners.bigCard};
    padding: 12px 20px;
    ${offset('m', 'gap')};

    ${mediaBreakpointUp('md')} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;
        max-width: 594px;
    }

    ${mediaBreakpointUp('xl')} {
        left: 12px;
    }
`;

export const Text = styled.p`
    cursor: default;
    ${font('text2-sb')};
    color: ${color('white')};

    a {
        transition: color 0.3s ease-in-out;
        text-decoration: underline;

        ${hover(`
            color: ${color('white', 0.8)}
        `)}
    }
`;
