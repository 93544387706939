import styled, { css } from 'styled-components';
import { Colors } from '@/style/colors';
import { color, hover, font, mediaBreakpointDown } from '@/style/mixins';

export const Button = styled.button<{ bgColor?: Colors; textColor?: Colors; variant: string; collapsed?: boolean }>`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ collapsed }) => (collapsed ? '8px' : '0')};
    height: 52px;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid transparent;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    ${({ variant }) => {
        switch (variant) {
            case 'black':
                return css`
                    color: ${color('white')};
                    background-color: ${color('DeepBlue/900')};

                    svg {
                        path {
                            fill: ${color('white')};
                        }
                    }

                    ${hover(`
                      & {
                        background-color:${color('DeepBlue/400')};
                      }
                    `)}
                `;
            case 'white':
                return css`
                    color: ${color('DeepBlue/400')};
                    background-color: ${color('white')};

                    svg {
                        path {
                            fill: ${color('DeepBlue/400')};
                        }
                    }

                    ${hover(`
                      & {
                        background-color:${color('BlueGray/200')};
                      }
                    `)}
                `;
            case 'white-bordered':
                return css`
                    color: ${color('DeepBlue/400')};
                    background-color: ${color('white')};
                    border: 1px solid ${color('DeepBlue/100')};

                    svg {
                        path {
                            fill: ${color('DeepBlue/400')};
                        }
                    }

                    ${hover(`
                      & {
                        background-color:${color('BlueGray/200')};
                      }
                    `)}
                `;
            case 'light-bg':
                return css`
                    color: ${color('DeepBlue/400')};
                    background-color: ${color('ui-bg')};

                    svg {
                        path {
                            fill: ${color('DeepBlue/400')};
                        }
                    }

                    ${hover(`
                      & {
                            background-color:${color('ui-bg-hover')};
                        }
                    `)}
                `;
            default:
                return '';
        }
    }}
`;

export const ButtonTitle = styled.span<{ collapsed?: boolean }>`
    ${font('ui')};
    white-space: nowrap;

    ${mediaBreakpointDown('xl')} {
        display: ${({ collapsed }) => (!collapsed ? 'flex' : 'none')};
    }
`;

export const ButtonIcon = styled.div`
    width: 14px;
    height: 14px;

    svg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: inherit;
        height: inherit;
    }
`;

export const ButtonAdditionalText = styled.span`
    flex-shrink: 0;
    color: ${color('brandPrimary')};
    ${font('ui')};
`;
